/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getShadow = /* GraphQL */ `
  query GetShadow($id: ID!) {
    getShadow(id: $id) {
      id
      customer
      description
      position
      available
      email
      date
      time
      hostalias
      location
      language
    }
  }
`;
export const listShadows = /* GraphQL */ `
  query ListShadows(
    $filter: ModelShadowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShadows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customer
        description
        position
        available
        email
        date
        time
        hostalias
        location
        language
      }
      nextToken
    }
  }
`;
export const getShadowSamAppDdBtableV4Ynyreyw9C6 = /* GraphQL */ `
  query GetShadowSamAppDdBtableV4Ynyreyw9C6($ID: String!) {
    getShadowSamAppDDBtableV4YNYREYW9C6(ID: $ID) {
      ID
      host
      event
      availability
      date
      time
      hostrole
      language
      location
      shadowemail
    }
  }
`;
export const listShadowSamAppDdBtableV4Ynyreyw9C6S = /* GraphQL */ `
  query ListShadowSamAppDdBtableV4Ynyreyw9C6S(
    $filter: TableShadowSamAppDDBtableV4YNYREYW9C6FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShadowSamAppDDBtableV4YNYREYW9C6S(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ID
        host
        event
        availability
        date
        time
        hostrole
        language
        location
        shadowemail
      }
      nextToken
    }
  }
`;
