import React from "react";
import bluecubes from '../assets/bluecubes.png'

export class Contact extends React.Component{
    render(){
        return (
        <div className="contact">
            <div className="home">
                <div className="Float-Right">
                    <h1 class="font-weight-light">Contact</h1>
                    <p>
                        For more information on this service, please visit our
                        <a href="https://w.amazon.com/bin/view/ShadowingAmazon"> wiki page</a>.
                        If you have any questions or would like to provide feedback,
                        please e-mail shadowing@amazon.com or reach out to any service team members.
                    </p>
                </div>
                <img width = "50%" className = "Contacts-box" height = "50%"  src={bluecubes}  alt="two big cubes"/>
            </div>
        </div>
    );
    }
}

