/* src/App.js */
import React from 'react'
import Amplify, { API, graphqlOperation , Auth} from 'aws-amplify'
import {listShadowSamAppDdBtableV4Ynyreyw9C6S} from '../graphql/queries'
import {updateShadowSamAppDdBtableV4Ynyreyw9C6} from '../graphql/mutations'
import "antd/dist/antd.css";
import { Table, Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import awsExports from '../aws-exports';
Amplify.configure(awsExports);
 
 
class Dashboard extends React.Component {
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };


   
 columns = [
   {
     title: "Event",
     dataIndex: "event",
     key: "event",
     align: 'center',
     width: '17%',
     ...this.getColumnSearchProps('event'),
   },
   {
    title: "Date",
    dataIndex: "date",
    key: "date",
    align: 'center',
    width: '15%',
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
    align: 'center',
  },
   {
     title: "Host",
     dataIndex: "host",
     key: "host",
     align: 'center',
     width: '10%',
   },
   {
    title: "Host Role",
    dataIndex: "hostrole",
    key: "hostrole",
    align: 'center',
     width: '15%',
  },
  {
    title: "Location",
    dataIndex: "location",
    align: 'center',
    key: "location"
  },   
  {
    title: "Language",
    dataIndex: "language",
    align: 'center',
    key: "language"
  },
   
 ];
 constructor(props){
   super(props);
   this.state = {shadows: [], tableData: [], searchText: '', searchedColumn: ''}
   this.fetchShadows()
}
    async fetchShadows() {
     try {
       const shadowData = await API.graphql(graphqlOperation(listShadowSamAppDdBtableV4Ynyreyw9C6S))
       console.log(shadowData.data)
       const currentUserInfo = await Auth.currentUserInfo()
       const email = currentUserInfo.attributes['email']
       const shadows = shadowData.data.listShadowSamAppDDBtableV4YNYREYW9C6S.items
       .filter((item) => item.shadowemail === email);
       const tableData = shadows.map ((shadow)=>{
         return {ID: shadow.ID, event: shadow.event, date: shadow.date, time: shadow.time,
          host: shadow.host, hostrole: shadow.hostrole,location: shadow.location, language: shadow.language,
          shadowemail: shadow.shadowemail}
       
       })
       this.setState({shadows,tableData})
     } catch (err) { console.log(err) }
   }

  
   styles = {
     container: { width: '100%"', margin: '0 auto', display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', padding: 20 }
   }
 
 render() {
  
   return (
     <div style={this.styles.container}>
       <h2>Dashboard</h2>
       <div className = "Align-Center">
       <p> This is a list of opportunities that you have attended or are scheduled to attend. </p>
        </div>
       <Table columns={this.columns} dataSource={this.state.tableData}  />
     </div>
   )
 }
 
}
 
 
 
export default Dashboard
 

 
