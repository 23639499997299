/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createShadow = /* GraphQL */ `
  mutation CreateShadow(
    $input: CreateShadowInput!
    $condition: ModelShadowConditionInput
  ) {
    createShadow(input: $input, condition: $condition) {
      id
      customer
      description
      position
      available
      email
      date
      time
      hostalias
      location
      language
    }
  }
`;
export const updateShadow = /* GraphQL */ `
  mutation UpdateShadow(
    $input: UpdateShadowInput!
    $condition: ModelShadowConditionInput
  ) {
    updateShadow(input: $input, condition: $condition) {
      id
      customer
      description
      position
      available
      email
      date
      time
      hostalias
      location
      language
    }
  }
`;
export const deleteShadow = /* GraphQL */ `
  mutation DeleteShadow(
    $input: DeleteShadowInput!
    $condition: ModelShadowConditionInput
  ) {
    deleteShadow(input: $input, condition: $condition) {
      id
      customer
      description
      position
      available
      email
      date
      time
      hostalias
      location
      language
    }
  }
`;
export const createShadowSamAppDdBtableV4Ynyreyw9C6 = /* GraphQL */ `
  mutation CreateShadowSamAppDdBtableV4Ynyreyw9C6(
    $input: CreateShadowSamAppDDBtableV4YNYREYW9C6Input!
  ) {
    createShadowSamAppDDBtableV4YNYREYW9C6(input: $input) {
      ID
      host
      event
      availability
      date
      time
      hostrole
      language
      location
      shadowemail
    }
  }
`;
export const updateShadowSamAppDdBtableV4Ynyreyw9C6 = /* GraphQL */ `
  mutation UpdateShadowSamAppDdBtableV4Ynyreyw9C6(
    $input: UpdateShadowSamAppDDBtableV4YNYREYW9C6Input!
  ) {
    updateShadowSamAppDDBtableV4YNYREYW9C6(input: $input) {
      ID
      host
      event
      availability
      date
      time
      hostrole
      language
      location
      shadowemail
    }
  }
`;
export const deleteShadowSamAppDdBtableV4Ynyreyw9C6 = /* GraphQL */ `
  mutation DeleteShadowSamAppDdBtableV4Ynyreyw9C6(
    $input: DeleteShadowSamAppDDBtableV4YNYREYW9C6Input!
  ) {
    deleteShadowSamAppDDBtableV4YNYREYW9C6(input: $input) {
      ID
      host
      event
      availability
      date
      time
      hostrole
      language
      location
      shadowemail
    }
  }
`;
