import React from "react";
import { Link, withRouter } from "react-router-dom";
import shadowmazonLogoWhite from '../assets/shadowmazonLogoWhite.png'

class Navigations extends React.Component{
    constructor(props){
        super(props);
        this.props = props
    }
        render(){
        return (
            <div className="navigation">
            <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
                <div class="container">
                <Link class="navbar-brand" to="/">
                
                </Link>
                <img width = "25%" height = "25%" src={shadowmazonLogoWhite} alt="shadowing amazon logo"/>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarResponsive"
                    aria-controls="navbarResponsive"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ml-auto">
                    <li
                        class={`nav-item  ${
                        this.props.location.pathname === "/" ? "active" : ""
                        }`}
                    >
                        <Link class="nav-link" to="/">
                        Home
                        <span class="sr-only">(current)</span>
                        </Link>
                    </li>
                    <li
                        class={`nav-item  ${
                        this.props.location.pathname === "/shadowtable" ? "active" : ""
                        }`}
                    >
                        <Link class="nav-link" to="/shadowtable">
                        Shadowing Opportunities
                        </Link>
                    </li>
                    <li
                        class={`nav-item  ${
                        this.props.location.pathname === "/dashboard" ? "active" : ""
                        }`}
                    >
                        <Link class="nav-link" to="/dashboard">
                        Dashboard
                        </Link>
                    </li>
                    <li
                        class={`nav-item  ${
                        this.props.location.pathname === "/contact" ? "active" : ""
                        }`}
                    >
                        <Link class="nav-link" to="/contact">
                        Contact
                        </Link>
                    </li>
                    </ul>
                </div>
                </div>
            </nav>
            </div>
        );
        }
    }

    export const Navigation = withRouter(Navigations);
