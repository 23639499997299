/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://yvahrhuuczetpdnwpd47ue5uay.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-fzvqovcvr5ambemvemod2lmdc4",
    "aws_cognito_identity_pool_id": "us-east-1:383c73d9-d416-4350-a269-9afc2f8c8f5b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_7hnvOq9gL",
    "aws_user_pools_web_client_id": "11ihv4u2dq4qtm448fvfrhqqqu",
    "oauth": {}
};


export default awsmobile;
