import React from 'react';
import logo from './logo.svg';
import './App.css';
import Shadowtable from './components/Shadowtable';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import  {Home} from './components/Home';
import  {Navigation} from './components/Navigation';
import  {Contact} from './components/Contact';
import  Dashboard from './components/Dashboard';
Amplify.configure(awsconfig);

 
function App(props) {
 return (
  <div className="App">
  <Router>
    <Navigation props = {props}/>
    <Switch>
      <Route path="/" exact component={() => <Home />} />
      <Route path="/shadowtable" exact component={() => <Shadowtable />} />
      <Route path="/dashboard" exact component={() => <Dashboard />} />
      <Route path="/contact" exact component={() => <Contact />} />
    </Switch>
  </Router>
</div>
 );
}
 
export default withAuthenticator(App);


