import React from 'react';
import '../App.css';
import LeadershipPrinciples  from '../assets/LeadershipPrinciples.png'
import redorangebanner  from '../assets/redorangebanner.png'
import {Button} from 'antd';


export class Home extends React.Component{
    render(){
        return (
            <div className="home">
                <div className="Float-Left">
                    <h3>Find and share shadowing opportunities.</h3>
                    <p> Shadowing Amazon intends to streamline the onboarding process for new hires, grow the Technical Field Communities 
         and provide more opportunities to learn. </p>
                    <Button
                        type="primary"
                        size="large"
                        href="/shadowtable"
                        shape="round"
                        style={{ padding: "0px 10px"}}
                        danger="true"
                        className="Top-Margin">
                        Find opportunities
                    </Button>
                </div>
                <img width = "50%" className = "App-box" height = "50%"  src={redorangebanner}  alt="two big cubes"/>
                <img className="App-panel" src={LeadershipPrinciples}></img>
            </div>
        );
    }
}



