/* src/App.js */
import React from 'react'
import Amplify, { API, graphqlOperation , Auth} from 'aws-amplify'
import {listShadowSamAppDdBtableV4Ynyreyw9C6S} from '../graphql/queries'
import {updateShadowSamAppDdBtableV4Ynyreyw9C6} from '../graphql/mutations'
import "antd/dist/antd.css";
import { Table, Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import awsExports from '../aws-exports';
import '../App.css';
Amplify.configure(awsExports);
 
 
class Shadowtable extends React.Component {
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };


   
 columns = [
   {
     title: "Event",
     dataIndex: "event",
     key: "event",
     align: 'center',
     width: '17%',
     ...this.getColumnSearchProps('event'),
   },
   {
    title: "Date",
    dataIndex: "date",
    key: "date",
    align: 'center',
    width: '15%',
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
    align: 'center',
  },
   {
     title: "Host",
     dataIndex: "host",
     key: "host",
     align: 'center',
     width: '10%',
   },
   {
    title: "Host Role",
    dataIndex: "hostrole",
    key: "hostrole",
    align: 'center',
     width: '15%',
     ...this.getColumnSearchProps('hostrole'),
  },
  {
    title: "Location",
    dataIndex: "location",
    align: 'center',
    key: "location"
  },   
  {
    title: "Language",
    dataIndex: "language",
    align: 'center',
    key: "language"
  },
   {
     title: "Availability",
     dataIndex: "availability",
     key: "availability",
     align: 'center',
     render: (text, shadow) => (    
     <Button type="primary" onClick={() => this.clickButton(shadow)} disabled = {!shadow.availability} >{shadow.availability ? "Attend" : "Unavailable"}</Button>
     ),
    
   }
 ];
 constructor(props){
   super(props);
   this.state = {shadows: [], tableData: [], searchText: '', searchedColumn: ''}
   this.fetchShadows()
}
    async fetchShadows() {
     try {
       const shadowData = await API.graphql(graphqlOperation(listShadowSamAppDdBtableV4Ynyreyw9C6S))
       console.log(shadowData.data)
       const shadows = shadowData.data.listShadowSamAppDDBtableV4YNYREYW9C6S.items
       const tableData = shadows.map ((shadow)=>{
         return {ID: shadow.ID, event: shadow.event, date: shadow.date, time: shadow.time,
          host: shadow.host, hostrole: shadow.hostrole,location: shadow.location, language: shadow.language,
          availability: shadow.availability, shadowemail: shadow.shadowemail}
       })
       this.setState({shadows,tableData})
     } catch (err) { console.log(err) }
   }
 
    async clickButton(shadow){
     shadow.availability = false
     const currentUserInfo = await Auth.currentUserInfo()
     const email = currentUserInfo.attributes['email']
     shadow.shadowemail = email
     console.log(shadow)
     this.setState({...this.state, tableData : this.state.tableData}) 
     await API.graphql(graphqlOperation(updateShadowSamAppDdBtableV4Ynyreyw9C6, {input:shadow}))
   }

  
   styles = {
     container: { width: '100%"', margin: '0 auto', display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', padding: 20 }
   }
 
 render() {
  
   return (
     <div style={this.styles.container}>
       <h2>Shadowing Opportunities</h2>
       
       <div className = "Align-Left">
         <p>At the moment, the minimum viable product supports Solutions Architects within Amazon Web Services. Opportunities can be shared by any Solutions Architect or Account Manager who is hosting a customer engagement.
         Solutions Architects are welcome to shadow any of the opportunities that are highlighted as "attend" on the page. 
        
        This table includes all opportunities that have not yet happened. </p>
        
       <p> If you would like to shadow an opportunity, please do the following:</p>
       <ol><li>Check your calendar and ensure that the opportunity that you would like to attend does not conflict with your calendar.</li>
         <li>Once you click "attend", a calendar invite will be sent to your email. </li>
        <li>Accept the calendar invite.</li></ol>
        </div>

       <Table columns={this.columns} dataSource={this.state.tableData}  />
     </div>
   )
 }
 
}
 
 
 
export default Shadowtable
 

 
